import Image from "next/image";
import VideoBlock from "../dao/VideoBlock";
import React, { useState } from "react";

const FancyBlock = () => {
  const fancyList = [
    ` Sustainable Unity – Uniting stakeholders to rebuild supply chains`,
    `  Smart Solutions –  Scaling up initiatives and identifying key resources.`,
    ` Empowered Support - Actively securing critical resources for sustainability`,
  ];
  const [videoModal, setvideoModal] = useState(false);

  return (
    <>
      {/* <!-- Promo --> */}
      <section className="relative py-24 dark:bg-jacarta-800">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <Image
            width={1519}
            height={773}
            priority
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full w-full object-cover"
          />
        </picture>
        <div className="container" >
          <div className="lg:flex lg:justify-between">
            {/* <!-- Image --> */}
            <div className="relative lg:w-[63%]">
              <div className="flex items-center space-x-7">
                <button
                  onClick={() => setvideoModal(true)}
                  className="js-video-modal-trigger absolute top-1/2 left-1/2 flex h-16 w-16 -translate-y-1/2 -translate-x-1/2 items-center justify-center rounded-full border-2 border-white transition-transform will-change-transform hover:scale-90"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="h-8 w-8 fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" />
                  </svg>
                </button>
                <Image
                  width={823}
                  height={562}
                  className="w-full h-full object-cover"
                  src="/images/dao/video_cover_dao.jpg"
                  alt="crypto app"
                />

                {/* <figure className="relative overflow-hidden before:absolute before:inset-0 before:bg-jacarta-900/25">
              
                  <Image
                    width={823}
                    height={562}
                    src="/images/dao/video_cover_dao.jpg"
                    className="w-full h-full object-cover"
                    alt="video"
                  />
                </figure> */}
                {/* <!-- end  video main block --> */}

                {/* <!-- YouTube Video Modal --> */}
                <div
                  className={
                    videoModal
                      ? "modal lightbox fade show block"
                      : "modal lightbox fade"
                  }
                >
                  <div className="modal-dialog modal-dialog-centered modal-xl w-full">
                    <div className="modal-content border-0 bg-transparent">
                      <div className="modal-body p-0 relative">
                        <button
                          onClick={() => setvideoModal(false)}
                          type="button"
                          className="btn-close position-absolute top-0 end-0 p-3 z-10"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            fill="#fff"
                            className="h-6 w-6"
                          >
                            <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z"></path>
                          </svg>
                        </button>
                        <div
                          id="lightboxCarousel-d7ewe4ig"
                          className="lightbox-carousel carousel"
                        >
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <div className="position-absolute top-50 start-50 translate-middle text-white">
                                <div
                                  className="spinner-border"
                                  role="status"
                                ></div>
                              </div>
                              <div className="ratio ratio-16x9">
                                <iframe
                                  src="https://www.youtube.com/embed/dV_d0tTSw4U"
                                  title="YouTube video player"
                                  allow="accelerometer clipboard-write encrypted-media gyroscope picture-in-picture autoplay"
                                ></iframe>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Info --> */}
            <div className="py-10 px-6 lg:w-[37%] lg:pl-28">
              <h2 className="mb-6 font-display text-3xl text-jacarta-700 dark:text-white">
                Impactoverse is pleased to partner with Sustain chain
              </h2>
              <p className="mb-12 text-lg leading-normal dark:text-jacarta-300">
                SustainChain™ is the world’s largest sustainability action
                platform that helps humanity collectively achieve the SDGs. As a
                public service platform, it brings together impact investors,
                innovators, purpose-driven brands, NGOs and alliances with a
                shared mission of building a more sustainable supply chain.
              </p>

              {fancyList.map((val, i) => (
                <div className="mb-3 flex space-x-4" key={i}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="h-8 w-8 shrink-0 fill-accent"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z" />
                  </svg>
                  <div>
                    <span className="mb-3 mt-2 block font-display text-base font-semibold text-jacarta-700 dark:text-white">
                      {val}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end promo -->  */}
    </>
  );
};

export default FancyBlock;
